<template>
  <div>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="form.employees"
      value="NIP"
      @handle-pick="pickItem"
    />
    <GroupNIPSearchDialog
      ref="groupNIPSearch"
      :lists="[]"
      value="groupid"
      @handle-pick="pickGroupItem"
    />
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ title }}
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row v-if="isDisplayUpdate">
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama/NIP</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                :value="`${form.name} - ${form.NIP}`"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <template v-if="single">
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama/NIP</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-if="form.employees[0]"
                  :value="
                    `${form.employees[0].name} - ${form.employees[0].NIP}`
                  "
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="12" class="py-1" v-else>
              <v-subheader class="caption headline-color font-weight-regular"
                >List Pegawai</v-subheader
              >
              <v-data-table
                id="table-custom-pegawai"
                class="mt-3"
                :headers="employeeHeaders"
                :items="form.employees"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <tr>
                    <td class="pt-2">
                      {{ item.NIP }}
                    </td>
                    <td class="pt-2">
                      {{ item.name }}
                    </td>
                    <td>
                      <v-btn
                        small
                        color="#FBB005"
                        class="ml-2 white--text"
                        @click="form.employees.splice(index, 1)"
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="$refs.employeeSearch.change(true)"
                  >Tambah<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="$refs.groupNIPSearch.change(true)"
                  >Tambah Group NIP<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row v-if="isDisplayUpdate">
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                :value="`${form.date}`"
                disabled
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Alasan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-autocomplete
                placeholder="Pilih Alasan"
                :items="types"
                v-model="form.note_id"
                outlined
                dense
                clearable
                item-text="note_ket"
                item-value="note_id"
                :disabled="disabled"
                :rules="[v => !!v || 'Alasan harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Keterangan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-textarea
                placeholder="Tulis Keterangan"
                v-model="form.trans_ket"
                outlined
                dense
                :disabled="disabled"
                :rules="[v => !!v || 'Keterangan harus diisi']"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="!isDisplayUpdate">
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Mulai</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                v-model="form.date_start"
                type="date"
                outlined
                dense
                :disabled="disabled"
                :rules="[v => !!v || 'Tanggal mulai harus diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Akhir</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                v-model="form.date_end"
                type="date"
                outlined
                dense
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!isDisplayUpdate && !disabled">
            <v-col cols="12" class="py-1 px-7">
              <p class="caption text-subtitle mb-0">
                Isi dengan tanggal yang sama atau kosongkan kolom tanggal kedua
                jika hanya izin 1 hari
              </p>
            </v-col>
          </v-row>
          <v-row v-if="(isDisplayUpdate || disabled) && form.attachment_url">
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Attachment</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-3">
              <a
                class="hover-primary caption"
                :href="form.attachment_url"
                target="_blank"
                >Lihat</a
              >
            </v-col>
          </v-row>
          <v-row v-if="!disabled">
            <v-col cols="12" class="py-1">
              <FileDraggable ref="fileDraggable" @on-change="handleOnChange" />
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <slot name="action">
              <v-btn
                small
                outlined
                color="#717171"
                class="px-12"
                @click="visible = false"
                >Batal</v-btn
              >
              <v-btn
                v-if="form.trans_id"
                small
                color="error"
                class="px-12"
                @click="removeNote"
                >Hapus</v-btn
              >
              <v-btn
                small
                color="#FBB005"
                class="px-12 subtitle-2 text-capitalize"
                :loading="formLoading"
                type="submit"
                :disabled="!valid"
                form="form"
                >{{ isDisplayUpdate ? "Ubah" : "Simpan" }}</v-btn
              >
            </slot>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-overlay :value="formLoading" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";
import PublicService from "@/services/resources/public.service";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");
const GroupNIPSearchDialog = () => import("@/components/Dialog/GroupNIPSearch");
const FileDraggable = () => import("@/components/File/Draggable");

export default {
  components: {
    EmployeeSearchDialog,
    FileDraggable,
    GroupNIPSearchDialog
  },
  props: {
    single: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      title: "Keterangan Izin",
      visible: false,
      valid: false,
      formLoading: false,
      types: [],
      form: {
        trans_id: null,
        name: null,
        NIP: null,
        note_id: null,
        trans_ket: null,
        date_start: null,
        date_end: null,
        date: null,
        attachment: null,
        attachment_url: null,
        status: null,
        employees: []
      },
      employeeHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "name",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.trans_id = null;
        this.form.NIP = null;
        this.form.name = null;
        this.form.note_id = null;
        this.form.trans_ket = null;
        this.form.date_start = null;
        this.form.date_end = null;
        this.form.date = null;
        this.form.attachment = null;
        this.form.attachment_url = null;
        this.form.employees = [];
        if (this.$refs.fileDraggable) {
          this.$refs.fileDraggable.closeAlert();
          this.$refs.fileDraggable.removeFile();
        }
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    isUpdate() {
      return this.form.trans_id != null;
    },
    isDisplayUpdate() {
      return this.form.trans_id != null || this.form.date != null;
    }
  },
  methods: {
    change(val) {
      this.title = "Tambah Catatan";
      this.visible = val;
    },
    edit(id) {
      this.title = "Edit Catatan";
      this.visible = true;
      this.getDetailNote(id);
    },
    changeSingle(employees) {
      this.title = "Buat Catatan";
      this.form.employees = employees;
      this.visible = true;
    },
    createSingle(item) {
      this.title = "Keterangan Izin";
      this.form.name = item.name;
      this.form.NIP = item.NIP;
      this.form.date = item.date;
      this.form.note_id = null;
      this.form.trans_ket = null;
      this.form.attachment = null;
      this.visible = true;
    },
    editSingle(item) {
      this.title = "Keterangan Izin";
      this.getDetailNote(item.trans_id, item);
      this.visible = true;
    },
    detail(item) {
      this.title = "Detail Catatan";
      this.form.employees = [
        {
          NIP: item.NIP,
          name: item.nama
        }
      ];
      this.form.date_start = item.date_start;
      this.form.date_end = item.date_end;
      this.form.note_id = item.note_id;
      this.form.trans_ket = item.trans_ket;
      this.form.attachment_url = item.attachment;
      this.visible = true;
    },
    pickItem(item) {
      if (item) {
        let index = this.form.employees.findIndex(d => d.NIP == item.NIP);
        if (index < 0) {
          this.form.employees.push({
            NIP: item.NIP,
            name: item.nama
          });
        }
      }
    },
    pickGroupItem(item) {
      if (item) {
        item.pegawaiList.map(pegawai => {
          this.pickItem(pegawai);
        });
      }
    },
    handleOnChange(val) {
      this.form.attachment = val;
    },
    removeNote() {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan menghapus catatan ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let formData = new FormData();
            formData.append("NIP[]", this.form.NIP);
            formData.append("date_start", this.form.date);
            formData.append("date_end", this.form.date);
            formData.append("remark", "");
            this.deleteNote(formData);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        // if (!this.form.attachment_url && !this.form.attachment) {
        //   this.$refs.fileDraggable.openAlert("File harus diisi", "error");
        //   return;
        // }
        let formData = new FormData();
        formData.append(
          "action",
          this.isUpdate ? 2 : this.form.date != null ? 4 : 1
        );
        formData.append("trans_id", this.form.trans_id);
        formData.append("note_id", this.form.note_id);
        formData.append("trans_ket", this.form.trans_ket);
        formData.append("date_start", this.form.date_start);
        if (this.form.employees.length > 0) {
          this.form.employees.forEach(employee => {
            formData.append("NIP[]", employee.NIP);
          });
        } else {
          formData.append("NIP[]", this.form.NIP);
        }
        formData.append("date_end", this.form.date_end || this.form.date_start);
        formData.append("date", this.form.date);
        formData.append("attachment", this.form.attachment);
        this.saveNote(formData);
      }
    },
    // Service
    async getListJenisCatatan() {
      try {
        this.loadingType = true;
        await PublicService.jenisCatatanGetList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.types = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingType = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveNote(data) {
      try {
        this.formLoading = true;
        await AttendanceService.saveNote(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$emit("on-refresh");
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailNote(id, item) {
      try {
        this.loading = true;
        await AttendanceService.detailNote(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              (this.form.trans_id = data.trans_id),
                (this.form.name = data.name ? data.name : item?.name),
                (this.form.NIP = data.NIP),
                (this.form.date = data.date),
                (this.form.note_id = data.note_id),
                (this.form.trans_ket = data.trans_ket),
                (this.form.status = data.status),
                (this.form.attachment_url = data.attachment);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteNote(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.deleteNote(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.$emit("on-refresh");
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListJenisCatatan();
  }
};
</script>
